.App {
  text-align: center;
}

.cs-app {
  width: 100%;
}

.MuiFormControl-root {
  width: 100%;
}
